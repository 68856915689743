import React from 'react'

import { Helmet } from 'react-helmet'

import NavigationLinks1 from '../components/navigation-links1'
import FeatureCard from '../components/feature-card'
import Question1 from '../components/question1'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Trakera</title>
        <meta property="og:title" content="Trakera" />
      </Helmet>
      <header data-role="Header" className="home-header">
        <img
          alt="logo"
          src="/external/logo%20hc-200h-1500h.png"
          className="home-image"
        />
        <div className="home-nav">
          <NavigationLinks1
            text="Home"
            text1="Nosotros"
            text2="Servicios"
            text3="Contacto"
            rootClassName="rootClassName10"
          ></NavigationLinks1>
        </div>
        <div className="home-btn-group">
          <a
            href="https://app.trakera.com"
            target="_blank"
            rel="noreferrer noopener"
            className="home-login button"
          >
            Ingresar
          </a>
          <button className="home-register button">
            <span>
              <span>Registrarse</span>
              <br></br>
            </span>
          </button>
        </div>
        <div data-role="BurgerMenu" className="home-burger-menu">
          <svg viewBox="0 0 1024 1024" className="home-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-role="MobileMenu" className="home-mobile-menu">
          <div>
            <div className="home-container01">
              <img
                alt="image"
                src="https://presentation-website-assets.teleporthq.io/logos/logo.png"
                className="home-image1"
              />
              <div data-role="CloseMobileMenu" className="home-menu-close">
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <NavigationLinks1 rootClassName="rootClassName11"></NavigationLinks1>
          </div>
          <div>
            <svg viewBox="0 0 950.8571428571428 1024" className="home-icon04">
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon06">
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg viewBox="0 0 602.2582857142856 1024" className="home-icon08">
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </header>
      <div id="Home" className="home-banner">
        <div className="home-container02">
          <h1 className="home-text03">
            Coordina de manera simple y ágil tu operación logística
          </h1>
          <span className="home-text04">
            <span className="home-text05">Diferénciate</span>
            <span> por tu nivel de servicio. </span>
            <br></br>
            <span>
              ¡De la tecnología nos encargamos nosotros y la dejamos al alcance
              de todos y no de unos pocos!
            </span>
            <br></br>
          </span>
          <div className="home-container03">
            <input
              type="text"
              placeholder="Correo electrónico..."
              className="home-textinput input"
            />
            <button className="home-button button">Comenzar</button>
          </div>
        </div>
        <img
          alt="image"
          src="/iconos%20landing%20page%20(7)-300h.png"
          className="home-image2"
        />
      </div>
      <div className="home-stats">
        <div className="home-stat">
          <img
            alt="image"
            src="/Iconos/iconos%20landing%20page%20(4)-200h.png"
            className="home-image3"
          />
          <span className="home-text10">Cargas</span>
          <span className="home-text11">
            Coordina tus cargas en un sólo lugar
          </span>
        </div>
        <div className="home-stat1">
          <img
            alt="image"
            src="/Iconos/iconos%20landing%20page%20(5)-200h.png"
            className="home-image4"
          />
          <span className="home-text12">Procesos</span>
          <span className="home-text13">Organiza tus procesos</span>
        </div>
        <div className="home-stat2">
          <img
            alt="image"
            src="/Iconos/iconos%20landing%20page%20(6)-200h.png"
            className="home-image5"
          />
          <span className="home-text14">Eficiencia</span>
          <span className="home-text15">
            Controla la logística de manera eficiente
          </span>
        </div>
      </div>
      <div id="Nosotros" className="home-banner1">
        <div className="home-container04">
          <h1 className="home-text16">
            <span>
              Bienvenido a
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text18">Trakera</span>
          </h1>
          <span className="home-text19">
            <span>
              Somos la plataforma digital que
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text21">
              transforma tu operación logística
            </span>
            <span>
              , maximizando la eficiencia, el control y la coordinación de tus
              servicios.
            </span>
            <br></br>
            <br></br>
            <span>
              No somos un forwarder,
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text26">somos tu aliado estratégico</span>
            <span>
              {' '}
              para que puedas diferenciarte por el nivel de servicio que
              ofreces, sin que la digitalización sea una barrera de entrada con
              tus clientes.
            </span>
            <br></br>
            <br></br>
            <span className="home-text30">¿Cómo lo logramos?</span>
            <br></br>
            <br></br>
            <span>
              Trakera permite que un agente de carga o Freight Forwarder pueda
              administrar su
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text34">
              operación de manera simple y sencilla.
            </span>
            <span>
              {' '}
              Nuestra plataforma te brinda las herramientas necesarias para
              gestionar tus servicios logísticos de manera
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text36">eficiente y coordinada</span>
            <span>
              , permitiéndote destacarte por la calidad de tu servicio.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </span>
        </div>
        <img
          alt="image"
          src="/firefly%20logistica%2024726-300h.jpg"
          className="home-image6"
        />
      </div>
      <div className="home-banner2">
        <span className="home-text42">con trakera</span>
        <h1 className="home-text43">TÚ TIENES EL CONTROL</h1>
      </div>
      <div className="home-features">
        <div className="featuresContainer">
          <div id="Servicios" className="home-features1">
            <div className="home-container05">
              <span className="home-text44">
                <span>servicios</span>
                <br></br>
              </span>
              <h2 className="home-features-heading">
                <span>
                  Potentes
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text48">servicios</span>
                <span>
                  {' '}
                  para una gestión logística
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text50">eficaz </span>
              </h2>
            </div>
            <div className="home-container06">
              <FeatureCard
                heading="Plataforma digital de coordinación de cargas"
                subHeading="Coordina tus cargas en un sólo lugar."
                rootClassName="feature-card-root-class-name4"
              ></FeatureCard>
              <FeatureCard
                heading="Gestor documental"
                subHeading="Organiza tus procesos."
                rootClassName="feature-card-root-class-name5"
              ></FeatureCard>
              <FeatureCard
                heading="Desarrollo de integraciones"
                subHeading="Controla la logística de manera eficiente."
                rootClassName="feature-card-root-class-name6"
              ></FeatureCard>
              <FeatureCard
                heading="Acceso a tus clientes al seguimiento y control de sus servicios"
                subHeading="Transparencia e información."
                rootClassName="feature-card-root-class-name7"
              ></FeatureCard>
            </div>
          </div>
        </div>
      </div>
      <div className="home-hero">
        <img
          alt="image"
          src="/firefly%20camion%20de%20log%C3%A3%C2%ADstica%20real%2049341-1500w.jpg"
          className="home-image7"
        />
        <div className="home-container07">
          <h1 className="home-text51">
            Maximizamos la calidad y coordinación en cada envío
          </h1>
          <h2 className="home-text52">
            Ayudamos a los Freight Forwarders y agentes de carga a maximizar la
            eficiencia, reducir costos y mejorar el servicio al cliente.
          </h2>
          <div className="home-btn-group1">
            <a
              href="https://app.trakera.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link button"
            >
              Comenzar
            </a>
            <a href="#Preguntas" className="home-link1 button">
              Leer más
            </a>
          </div>
        </div>
      </div>
      <div id="Contacto" className="home-hero1">
        <div className="home-container08">
          <h1 className="home-text53">Cotiza con nosotros</h1>
          <form
            name="formulario-contacto"
            action="https://formsubmit.co/gustavo.villena@trakera.com "
            method="POST"
            enctype="application/x-www-form-urlencoded"
            autoComplete="on"
            className="home-form"
          >
            <input
              type="text"
              id="business_name"
              name="business_name"
              required="true"
              placeholder="Nombre empresa"
              autoComplete="name"
              className="home-textinput1 input"
            />
            <input
              type="text"
              id="name"
              name="name"
              required="true"
              placeholder="Nombre completo"
              autoComplete="name"
              className="home-textinput2 input"
            />
            <input
              type="email"
              id="email"
              name="email"
              required="true"
              placeholder="Correo electrónico"
              autoComplete="email"
              className="home-textinput3 input"
            />
            <input
              type="tel"
              id="phone"
              name="phone"
              required="true"
              placeholder="Celular (+569)"
              autoComplete="tel"
              className="home-textinput4 input"
            />
            <input
              type="text"
              id="message"
              name="message"
              required="true"
              placeholder="Mensaje"
              className="home-textinput5 input"
            />
            <button type="submit" className="home-button1 button">
              Enviar
            </button>
          </form>
        </div>
      </div>
      <div className="home-faq">
        <div className="faqContainer">
          <div id="Preguntas" className="home-faq1">
            <div className="home-container09">
              <h2 className="home-text54">Preguntas frecuentes</h2>
            </div>
            <div className="home-container10">
              <Question1
                answer="Trakera es una plataforma que ayuda a los Freight Forwarder a digitalizar sus órdenes y coordinar de manera simple sus envíos. No somos un Forwarder, somos la plataforma que buscar ayudar a los Forwarder para que se diferencien por nivel de servicio y no por tecnología."
                question="¿Qué es Trakera?"
              ></Question1>
              <Question1
                answer="Trakera ofrece una plataforma digital de coordinación de cargas, gestor documental, desarrollo de integraciones y acceso para que tus clientes puedan hacer seguimiento y control de sus servicios."
                question="¿Qué servicios ofrece Trakera?"
              ></Question1>
              <Question1
                answer="Trakera permite maximizar, eficientar, controlar y coordinar tus envíos. Además, optimiza tus procesos, te permite interactuar con tus clientes de manera más simple y gestionar los documentos en un solo lugar."
                question="¿Por qué debería elegir Trakera?"
              ></Question1>
              <Question1
                answer="Si es posible, ofrecemos la posibilidad de desarrollo de APIs para integrar tus datos de embarques con otras herramientas y sistemas."
                question="¿Trakera se puede integrar con otros software o sistemas?"
              ></Question1>
              <Question1
                answer="Nuestra aplicación web funciona de preferencia en los navegadores y podemos desarrollar acceso apps móviles para iOS y Android en caso de ser necesario."
                question="¿Desde que dispositivos se puede acceder a la plataforma de Trakera?"
              ></Question1>
            </div>
          </div>
        </div>
      </div>
      <div className="home-footer">
        <footer className="footerContainer home-footer1">
          <div className="home-container11">
            <img
              alt="image"
              src="/external/logo%20hc-200h-1500h.png"
              className="home-image8"
            />
            <nav className="home-nav2 home-nav2 bodySmall">
              <a href="#Home" className="home-nav1 home-nav1">
                Home
              </a>
              <a href="#Nosotros">Nosotros</a>
              <a href="#Servicios" className="home-nav3 bodySmall">
                Servicios
              </a>
              <a href="#Contacto" className="home-nav4 bodySmall">
                Contacto
              </a>
            </nav>
          </div>
          <div className="home-separator"></div>
          <div className="home-container12">
            <span className="home-text55 bodySmall">
              © 2024 Trakera, Todos los derechos reservados.
            </span>
            <div className="home-icon-group1">
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="home-icon10 socialIcons"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <a
                href="https://www.linkedin.com/company/trakera/about/?viewAsMember=true"
                target="_blank"
                rel="noreferrer noopener"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="home-icon12"
                >
                  <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
                </svg>
              </a>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="home-icon14 socialIcons"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="home-icon16 socialIcons"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Home
