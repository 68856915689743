import React from 'react'

import PropTypes from 'prop-types'

import './navigation-links1.css'

const NavigationLinks1 = (props) => {
  return (
    <nav className={`navigation-links1-nav ${props.rootClassName} `}>
      <a href="#Contacto" className="navigation-links1-home">
        {props.text}
      </a>
      <a href="#Nosotros" className="navigation-links1-link">
        {props.text1}
      </a>
      <a href="#Servicios" className="navigation-links1-link1">
        {props.text2}
      </a>
      <a href="#Contacto" className="navigation-links1-link2">
        {props.text3}
      </a>
    </nav>
  )
}

NavigationLinks1.defaultProps = {
  text3: 'Contacto',
  rootClassName: '',
  text4: 'Blog',
  text: 'Home',
  text1: 'Nosotros',
  text2: 'Servicios',
}

NavigationLinks1.propTypes = {
  text3: PropTypes.string,
  rootClassName: PropTypes.string,
  text4: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
}

export default NavigationLinks1
